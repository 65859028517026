.project-card {
    border: 1.5px solid #6751b9;
    border-radius: 0.65rem;
    padding: 2.5rem;
    text-align: center;
    position: relative;
    cursor: pointer;
    transition: all 0.3s ease;
    width: 500px;
    height: 300px;
    margin-right: 20px;
    /* Yanındaki öğeler arasındaki boşluğu ayarlayın */
}

.project-card:hover,
.project-card:active {
    width: 1000px;
    /* Seçilen öğenin genişliğini büyütün */
    margin-right: 0;
    /* Seçilen öğenin yanındaki öğelerle boşluk bırakmayın */
}

.project-card:not(:hover):not(:active) {
    width: 480px;
    /* Seçilmemiş öğelerin genişliğini küçültün */
}

.project-icon {
    display: flex;
    align-items: start;
    justify-content: center;
    position: absolute;
    bottom: 25px;
    left: 25px;
    border-radius: 50%;
    width: 60px;
    height: 60px;

    img {
        width: 2.4rem;
        height: auto;
        object-fit: contain;
    }
}

.project-title{
    display: none;
}

@media (max-width: 770px) {
    .project-card span {
        font-size: 0.9rem;
    }

    .project-card:hover,
    .project-card:active {
        width: auto;
        /* Seçilen öğenin genişliğini büyütün */
        margin-right: 0;
        /* Seçilen öğenin yanındaki öğelerle boşluk bırakmayın */
    }

    .project-card:not(:hover):not(:active) {
        width: 450px;
        height: 300px;
        /* Seçilmemiş öğelerin genişliğini küçültün */
    }

    .project-card {
        border: 1.5px solid #6751b9;
        border-radius: 0.65rem;
        padding: 2.5rem;
        text-align: center;
        position: relative;
        cursor: pointer;
        transition: all 0.3s ease;
        width: 200px;
        height: 200px;
        margin-bottom: 50px;
        justify-content: center;

        /* Yanındaki öğeler arasındaki boşluğu ayarlayın */
    }
    .project-title {
        display: flex;
        justify-content: center;
        background-image: url("../../../../Lib/Images/Purple\ ribbon.png");
        background-position: center;
        background-size: cover;
        width: 250px;
        height: 40px;
        text-align: center;
    }


}


@media (max-width: 450px) {
    .skills-card span {
        font-size: 0.9rem;
    }

    .project-card:hover,
    .project-card:active {
        width: 250px;
        /* Seçilen öğenin genişliğini büyütün */
        /* Seçilen öğenin yanındaki öğelerle boşluk bırakmayın */
    }

    .project-card:not(:hover):not(:active) {
        width: 250px;
        height: 250px;
        /* Seçilmemiş öğelerin genişliğini küçültün */
    }

    .project-card {
        border: 1.5px solid #6751b9;
        border-radius: 0.65rem;
        padding: 2.5rem;
        text-align: center;
        position: relative;
        cursor: pointer;
        transition: all 0.3s ease;
        width: 250px;
        height: 250px;
        margin-bottom: 50px;



        /* Yanındaki öğeler arasındaki boşluğu ayarlayın */
    }

    .project-icon {
        display: flex;
        align-items: start;
        justify-content: center;
        position: absolute;
        bottom: 0px;
        left: 0px;

        img {
            width: 1.8rem;
            height: auto;
            object-fit: contain;
        }
    }
    .project-title {
        display: flex;
        justify-content: center;
        background-image: url("../../../../Lib/Images/Purple\ ribbon.png");
        background-position: center;
        background-size: cover;
        width: 250px;
        height: 40px;
        text-align: center;
        margin-right: 20px;
    }


}