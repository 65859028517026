.hero-container {
    display: flex;
    align-items: center;
    position: relative;
    gap: 5rem;
    margin: 5vh;
    margin-bottom: 10vh;
}

.hero-content {
    flex: 1;
}

.hero-container::after,
.hero-container::before {
    content: " ";
    width: 28.125rem;
    height: 28.125rem;
    border-radius: 28.125rem;
    background: #7c66e3;
    position: absolute;
    z-index: -1;
    filter: blur(225px);
}

.hero-container::after {
    top: -3rem;
    left: -5rem;
}

.hero-container::before {
    bottom: 2rem;
    right: 0rem;
}

.hero-container h2 {
    font-size: 3.8rem;
    font-weight: 600;
    line-height: 5rem;
    margin-bottom: 1rem;
}

.hero-content p {
    font-size: 1rem;
    font-weight: 300;
    line-height: 2rem;

}

.hero-img {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    margin-top: 5rem;
}

.hero-img>div {
    display: flex;
    align-items: flex-end;
    gap: 2rem;
}

.hero-img>div>img {
    width: 25rem;
    transition: all 0.3s ease;
}

.tech-icon {
    width: 5rem;
    height: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 0.65rem;
    border: 1.5px solid #6852ba;
}

.tech-icon img {
    width: 3rem;
    height: auto;
    transition: all 0.3s ease;
}

.hero-img>div>img:hover,
.tech-icon img:hover {
    transform: translateY(-0.5rem);

}

.hero{
    border-radius:10%;
}

@media (max-width:1025px) {
    .hero{
        border-radius:10%;
    }
    .hero-content h2 {
        font-size: 3rem;
        line-height: 4rem;
    }

    .hero-content p {
        font-size: 0.9rem;
        line-height: 1.6rem;
    }

    .hero-img>div>img {
        width: 20rem;
    }

    .tech-icon {
        width: 4rem;
        height: 4rem;
    }

    .tech-icon img {
        width: 2.5rem;
    }

}

@media (max-width:768px) {
    .hero{
        border-radius:10%;
    }
    .hero-container {

        flex-direction: column;

    }

    .hero-img{
        margin: 2rem 0 2rem 0;
    }

    .hero-container h2 {
        margin-top: 3rem;

    }

    .hero-content h2,.hero-content p{
        width: auto;
        text-align: center ;
    }

    .hero-container::after,.hero-container::before{
        content: " ";
        width: 18rem;
        height: 18rem;
    }

    .hero-container::after{
        top: 0rem;
        left: 0rem;
    }

    .hero-container::before{
        bottom: 2rem;
        left: 0rem;
    }
}

@media (max-width:600px) {
    .hero{
        border-radius:10%;
    }
    .hero-container h2{
        font-size: 2.2rem;
        line-height: 3rem;
    }

    .hero-container p{
        font-size: 0.8rem;
        line-height: 1.3rem;
    }

    .hero-img{
        gap:1rem;
    }

    .hero-img > img {
        gap: 1rem;
    }

    .hero-img > div > img {
        width:  16rem;
    }

    .tech-icon{
        width: 3.3rem;
        height: 3.3rem;
    }

    .tech-icon img {
        width: 2rem;
    }
}