/* ContactInfo.css */
.contact {
    display: flex;
    justify-content: flex-end;
    margin: 1rem;
  }

  .contact-content {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
