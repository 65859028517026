.experience-container{
  margin: 4rem 0;
  position: relative;
  margin-bottom: 8rem;
}

.experience-container h5{
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 3rem;

}