.projects-container{
margin-top: 100px;
position: relative;
margin-bottom: 20vh;
}

.projects-container h5 {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 3.5rem;
}

.projects-content{
display: flex;
flex-direction: row;
align-items: start;
gap: 4rem;
}

.projects{
    flex: 1;
    display: contents;
    grid-gap: 3rem;
    grid-template-columns: repeat(2,1fr);

}

.projects-info{
    flex: 1;
}

@media (max-width: 768px) {

    .projects{
        flex: 1;
        display: ruby;
        margin: auto;
        grid-template-columns: repeat(2,1fr);
    }

    .projects-content{
        display: grid;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 4rem;
        }

}