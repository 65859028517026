@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

*{
  font-family: "Poppins",sans-serif;
  margin: 0;
  box-sizing: border-box;
}

body {
  color:#fff ;
  background-color: #060417;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

