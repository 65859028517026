@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");
.nav-wrapper {
    background: rgba(0,0,0,0.1);
    padding: 0.5rem 0;
    position: sticky;
    top: 0;
    z-index: 30;
    backdrop-filter: blur(50px);
}

.cv-link{
    text-decoration: none;
}

.nav-content{
    max-width: 1700px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0;
    margin: 0 auto;
}

.logo{
    width: 200px;
    height: 66px;
    margin-left: 0rem;
}

.nav-content ul{
    display: flex;
    align-items:center ;
    gap: 0.5rem;
    list-style: none;
    margin-right: 4vh;
}

.nav-content li {
    margin: 0 1.5rem;
}

.menu-item {
    text-decoration: none;
    font-size: 1rem;
    font-weight:500 ;
    color: #fff;
    position: relative;
    cursor: pointer;

}

.menu-item::before{
    content: " ";
    width: 0;
    height: 0.2rem;
    background: linear-gradient(90deg, #a993fe 0%, #7e61e7 100%);
    border-radius: 0.5rem;
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    opacity: 0;
    transition: all 0.3s ease;
}

.menu-item:hover::before {
    width: 100%;
    opacity: 1;
}

.container-btn {
    font-size: 1rem;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: linear-gradient(90deg, #a993fe 0%, #7e61e7 100%);
    padding: 0.6rem 2rem;
    border: none;
    outline: 1.5px solid transparent;
    border-radius: 0.2rem;
    cursor: pointer;
    transition: all 0.3 ease;
}

.container-btn:hover{
    color:#a993fe;
    background: #000;
    outline: 1.5px solid #a993fe;
}

.menu-btn {
    width: 2.5rem;
    height: 2.5rem;
    font-size: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    border-radius: 0.4rem;
    color: #fff;
    background: linear-gradient(90deg, #a993fe -0.13%, #7e61e7 99.87%);
    line-height: 0;
    cursor: pointer;
    transition: all 0.4s ease; /* "s" birimi eklendi */
    display: none;
    margin-right: 3vh;
}

.menu-btn:hover{
color: #a993fe;
background: #000;
border: 1px solid #a993fe
}

@media (max-width:769px) {
    .menu-btn{
        display: block;
    }
    .nav-content ul {
        display: none;
    }
    .logo{
        width: 200px;
        height: 66px;
        margin: 1rem;
    }

}