/*.footer{
    width: 100%;
    border-top: 1px solid #6852ba;
    padding: 50px;
}
.footer-container {
    display: flex;
    flex-direction: column;
    align-items: end;
  }

.footer-contact-me {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
  }

.footer-content {
    margin-top: 20px;
  }
*/
.footer{
font-size: 0.8rem;
text-align: center;
background: linear-gradient(90deg, #3D3072 0%,#423477 100%);
padding: 1rem;
}